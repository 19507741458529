import React, { useEffect, useState } from 'react'
import { GetLatestClasses } from '../../../service/service'
import { useNavigate } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import DataItem from '../../../ReuseableComponents/DataItem';

function LatestClasses() {
    const navigate = useNavigate();
    const userData = useSelector((state) => state.userdata.userdata)
    const [loading, setLoading] = useState(true);
    const [bookmarkLoading, setBookmarkLoading] = useState({ loadingId: null });
    const [data, setData] = useState([])
    const [isLoggedin, setIsLoggedin] = useState(false)

    useEffect(() => {
        GetLatestClasses().then((result) => {
            if (result.status) {
                setData(result.data)
                setLoading(false)
            }

        })
        if (userData) {

            setIsLoggedin(true)
        }
        else {
            setIsLoggedin(false)
        }
    }, [])

    const ViewAll = () => {
        navigate(`/latest-classes`, { state: { data } });
    }

    return (
        <section id='latest-classes' className='px-2 xs:px-5 py-8 lg:py-[60px]'>
            <div className="2xl:max-w-2xl xl:max-w-xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto">
                {
                    loading ?
                        <div className='py-4 sm:py-8 space-y-3 sm:space-y-4'>

                            <div><Skeleton height={80} /></div>
                            <div><Skeleton height={80} /></div>
                            <div><Skeleton height={80} /></div>
                        </div>
                        :
                        <>
                            <div className='flex justify-between items-center'>
                                <div className='text-primaryDark text-2xl font-extrabold leading-[normal] lg:text-4xl'>
                                    Latest Classes
                                </div>
                                <div onClick={ViewAll} className='text-primaryBlue flex justify-center items-center cursor-pointer'>
                                    <span className='text-base font-semibold leading-7'>
                                        View all
                                    </span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M17.92 11.6202C17.8724 11.4974 17.801 11.3853 17.71 11.2902L12.71 6.29019C12.6168 6.19695 12.5061 6.12299 12.3842 6.07253C12.2624 6.02207 12.1319 5.99609 12 5.99609C11.7337 5.99609 11.4783 6.10188 11.29 6.29019C11.1968 6.38342 11.1228 6.49411 11.0723 6.61594C11.0219 6.73776 10.9959 6.86833 10.9959 7.00019C10.9959 7.26649 11.1017 7.52188 11.29 7.71019L14.59 11.0002H7C6.73478 11.0002 6.48043 11.1055 6.29289 11.2931C6.10536 11.4806 6 11.735 6 12.0002C6 12.2654 6.10536 12.5198 6.29289 12.7073C6.48043 12.8948 6.73478 13.0002 7 13.0002H14.59L11.29 16.2902C11.1963 16.3831 11.1219 16.4937 11.0711 16.6156C11.0203 16.7375 10.9942 16.8682 10.9942 17.0002C10.9942 17.1322 11.0203 17.2629 11.0711 17.3848C11.1219 17.5066 11.1963 17.6172 11.29 17.7102C11.383 17.8039 11.4936 17.8783 11.6154 17.9291C11.7373 17.9798 11.868 18.006 12 18.006C12.132 18.006 12.2627 17.9798 12.3846 17.9291C12.5064 17.8783 12.617 17.8039 12.71 17.7102L17.71 12.7102C17.801 12.6151 17.8724 12.5029 17.92 12.3802C18.02 12.1367 18.02 11.8636 17.92 11.6202Z" fill="#186CCE" />
                                    </svg>
                                </div>
                            </div>
                            {/* <div className='vertical-scrollbar pt-3 lg:pt-9 flex justify-between gap-4 xl:gap-[unset] overflow-x-auto'>
                    {[1, 2, 3, 4, 5].slice(0, 4).map((index) => (
                        <div key={`item-${index}`} className='flex- flex-col h-[200px] w-[167px] lg:h-[321px] lg:w-[274px] 2xl:w-[unset] bg-[#E1E9F2] rounded-[10.68px]'>
                            <div className='h-[65%] flex justify-center items-center lg:w-[274px] 2xl:w-[unset]'>
                                <div className='p-3 lg:p-6 rounded-full bg-whiteGrey'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" className='lg:w-[62px] lg:h-[62px]'>
                                        <path d="M4.39947 11.2998H27.0913C27.6645 11.2998 28.2142 11.5275 28.6195 11.9328C29.0248 12.3381 29.2524 12.8878 29.2524 13.4609V26.4277C29.2524 27.0009 29.0248 27.5506 28.6195 27.9558C28.2142 28.3611 27.6645 28.5888 27.0913 28.5888H4.39947C3.8263 28.5888 3.27661 28.3611 2.87132 27.9558C2.46603 27.5506 2.23834 27.0009 2.23834 26.4277V13.4609C2.23834 12.8878 2.46603 12.3381 2.87132 11.9328C3.27661 11.5275 3.8263 11.2998 4.39947 11.2998Z" fill="#161E2E" />
                                        <path d="M20.1405 21.9374V15.7781C20.1401 15.694 20.1196 15.6111 20.0804 15.5355C20.0413 15.4599 19.9846 15.3935 19.9144 15.3414C19.8453 15.2888 19.7645 15.2514 19.678 15.232C19.5915 15.2126 19.5015 15.2118 19.4146 15.2294L14.0593 16.3493C13.9271 16.3773 13.809 16.447 13.7246 16.5468C13.6402 16.6466 13.5946 16.7705 13.5952 16.898V21.5679C13.189 21.3874 12.7315 21.3358 12.2915 21.4207C11.8514 21.5056 11.4525 21.7225 11.1546 22.0388C10.8566 22.3551 10.6757 22.7539 10.6389 23.1752C10.6022 23.5964 10.7116 24.0176 10.9507 24.3754C11.1899 24.7332 11.5459 25.0084 11.9653 25.1597C12.3848 25.311 12.845 25.3303 13.2771 25.2146C13.7091 25.0989 14.0896 24.8544 14.3616 24.518C14.6335 24.1815 14.7822 23.7712 14.7852 23.3485C14.7914 23.2515 14.7914 23.1543 14.7852 23.0573V17.346L18.9505 16.4781V20.448C18.544 20.2674 18.0863 20.2158 17.6461 20.3009C17.2058 20.386 16.8067 20.6032 16.5089 20.92C16.211 21.2367 16.0303 21.6358 15.994 22.0573C15.9577 22.4789 16.0677 22.9002 16.3076 23.2579C16.5474 23.6156 16.9041 23.8904 17.324 24.0411C17.744 24.1918 18.2046 24.2102 18.6366 24.0935C19.0686 23.9769 19.4488 23.7315 19.72 23.3942C19.9912 23.0569 20.1387 22.6459 20.1405 22.223C20.1462 22.1279 20.1462 22.0325 20.1405 21.9374Z" fill="#D4DDE9" />
                                        <path d="M7.56402 2.80957H23.7725C24.0591 2.80957 24.3339 2.92342 24.5366 3.12606C24.7392 3.32871 24.8531 3.60355 24.8531 3.89013C24.8531 4.17672 24.7392 4.45156 24.5366 4.65421C24.3339 4.85685 24.0591 4.9707 23.7725 4.9707H7.56402C7.27744 4.9707 7.00259 4.85685 6.79995 4.65421C6.5973 4.45156 6.48346 4.17672 6.48346 3.89013C6.48346 3.60355 6.5973 3.32871 6.79995 3.12606C7.00259 2.92342 7.27744 2.80957 7.56402 2.80957Z" fill="#161E2E" />
                                        <path d="M5.40295 7.13184H25.9337C26.2202 7.13184 26.4951 7.24568 26.6977 7.44833C26.9004 7.65097 27.0142 7.92582 27.0142 8.2124C27.0142 8.49898 26.9004 8.77383 26.6977 8.97647C26.4951 9.17912 26.2202 9.29296 25.9337 9.29296H5.40295C5.11637 9.29296 4.84152 9.17912 4.63888 8.97647C4.43623 8.77383 4.32239 8.49898 4.32239 8.2124C4.32239 7.92582 4.43623 7.65097 4.63888 7.44833C4.84152 7.24568 5.11637 7.13184 5.40295 7.13184Z" fill="#161E2E" />
                                    </svg>
                                </div>
                            </div>
                            <div className='h-[35%] bg-primaryDark text-whiteGrey rounded-br-[10.68px] rounded-bl-[10.68px] p-3'>
                                <div className='text-sm lg:text-2xl font-extrabold truncate max-w-full'>
                                    Series on the ”King in the
                                </div>
                                <div className='text-xs lg:text-base font-semibold space-x-3 lg:space-x-4 pt-2'>
                                    <span>
                                        Series
                                    </span>
                                    <span>
                                        .
                                    </span>
                                    <span>
                                        5 Classes
                                    </span>
                                </div>
                            </div>

                        </div>
                    ))}
                </div> */}
                        <div className='py-4 sm:py-8 space-y-3 sm:space-y-4'>
                            {
                                data?.slice(0, 3).map((obj) => (
                                    <DataItem
                                        latestClasses={true}
                                        classes={true}  
                                        key={obj.id}
                                        dataObj={obj}
                                        data={data}
                                        setData={setData} 
                                        bookmarkLoading={bookmarkLoading} 
                                        setBookmarkLoading={setBookmarkLoading} 
                                        isLoggedin={isLoggedin} 
                                    />
                                ))
                            }
                        </div>
                    </>
                }

            </div>
        </section>
    )
}

export default LatestClasses