import { useNavigate } from 'react-router-dom';
import { ClassesIcon, SeriesIcon, CategoryIcon } from '../assets/icon-file';
import sanitizeDescription from './sanitizeDescription'
import DataItemBookmark from './DataItemBookmark';

export default function DataItem({
  popularClasses,
  latestClasses,
  searchPage,
  viewAll,
  bookmarks,
  monthlyClasses,
  weeklyParsha,
  classes,
  series,
  category,
  dataObj,
  prevData,
  data,
  setData,
  bookmarkLoading,
  setBookmarkLoading,
  isLoggedin
}) {
  const navigate = useNavigate();
  const pagesWithBookmarks = popularClasses || latestClasses || viewAll || bookmarks;

  const openItem = (event) => {
    // prevents site from refreshing before loading new page loads
    event.preventDefault();
    classes ?
      navigate(`/${dataObj.custom_permalink ?? dataObj.slug}`)
    :
    series ? 
      navigate(`/series/${dataObj.perma_link ?? dataObj.slug}`)
    :
    navigate(`/category/${dataObj?.slug}`);
  }

  const newTabLinks = () => {
    return (
      classes ? `/${dataObj.custom_permalink ?? dataObj.slug}`
      :
      series ? `/series/${dataObj.permalink ?? dataObj.slug}`
      :
      `/category/${dataObj?.slug}`
    )
  }

  return (
    <div className="rounded-xl bg-[#E1E9F2] h-[68px] sm:h-[95px] flex grow">
      <a href={newTabLinks()} 
        onClick={(event) => openItem(event)} className='cursor-pointer grow pl-3 sm:pl-6 flex items-center gap-x-2 sm:gap-x-4'
      >
        <div className='h-[40px] w-[40px] xs:h-[44px] xs:w-[58px] sm:h-[63px] sm:w-[80px] px-6 bg-whiteGrey flex items-center justify-center rounded-lg'>
          <span>
            {
              classes && <ClassesIcon className='w-[25px] h-[25px] xs:w-[27px] xs:h-[27px] sm:w-[32px] sm:h-[32px]' xmlns="http://www.w3.org/2000/svg" />
            }
            {
              series && <SeriesIcon className="w-[25px] h-[25px] xs:w-[27px] xs:h-[27px] sm:w-[32px] sm:h-[32px]"/>
            }
            {
              category && <CategoryIcon className="w-[25px] h-[25px] xs:w-[27px] xs:h-[27px] sm:w-[32px] sm:h-[32px]" />
            }
          </span>
        </div>
        <div className='text-primaryDark'>
          <div className='text-base xs:text-lg sm:text-2xl font-extrabold leading-[normal] line-clamp-1'>{dataObj.title || dataObj.name}</div>
          {/* These conditions maintain the current working status of the site. They do not get descriptions to display everywhere, but this will eventually be updated to the desired format.  */}
            {
              (viewAll && classes) || latestClasses ? (
                dataObj.block?.map((item) => (
                  <div key={item.id} >
                    {
                      item.type === 'Paragraph' &&
                      <div className='text-xs xs:text-sm sm:text-base font-semibold leading-[normal] line-clamp-1'>{item.description}</div>
                    }
                  </div>
                ))
              ) : 
              (searchPage && classes) ? (
                dataObj.block?.map((item) => (
                  <div key={item.id} >
                    {
                      item.type === 'Custom HTML' &&
                      <div dangerouslySetInnerHTML={{ __html: item.html != null ? sanitizeDescription({title: dataObj.title, description: item.html, type: item.type}) : '' }} 
                      className='text-xs xs:text-sm sm:text-base font-semibold leading-[normal] line-clamp-1' />
                    }
                  </div>
                ))
              ) : monthlyClasses || weeklyParsha ? (
                  <div className='text-xs xs:text-sm sm:text-base font-semibold leading-[normal] line-clamp-1'>{dataObj.description}</div>
              ) : (
                <div dangerouslySetInnerHTML={{ __html: dataObj.description != null ? sanitizeDescription({title: dataObj.title, description: dataObj.description, type: dataObj.type}) : '' }} 
                className='text-xs xs:text-sm sm:text-base font-semibold leading-[normal] line-clamp-1' />
              )
            }
        </div>
      </a>
      {
        // These pages have bookmarks at the end of the box containing the text
        pagesWithBookmarks && isLoggedin ? (
          <DataItemBookmark
            classes={classes}
            series={series}
            dataObj={dataObj}
            prevData={prevData}
            data={data}
            setData={setData}
            bookmarkLoading={bookmarkLoading}
            setBookmarkLoading={setBookmarkLoading}
          />
        ) : (
          // default padding
          <a href={newTabLinks()}
            onClick={(event) => openItem(event)} className="cursor-pointer pr-3 sm:pr-6"
          > </a>
        )
      }
    </div>
  )
}