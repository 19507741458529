import React, { useEffect, useState } from 'react';
import { FacebookIcon, FacebookShareButton, TwitterShareButton, WhatsappIcon, WhatsappShareButton, XIcon } from 'react-share';
import DataItem from '../../ReuseableComponents/DataItem';

function UserCategory({ data }) {
    const shareUrl = window.location.href;
    const [items, setItems] = useState([]);

    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
    
        // Optionally, you can also add a listener for navigation changes
        const handleScrollToTop = () => {
          window.scrollTo(0, 0);
        };
    
        // Attach the listener to the "beforeunload" event
        window.addEventListener('beforeunload', handleScrollToTop);
    
        // Clean up the listener when the component unmounts
        return () => {
          window.removeEventListener('beforeunload', handleScrollToTop);
        };
      }, []);
    useEffect(() => {
        // const sortedData = data?.list.sort((a, b) => parseInt(a.order_sequence) - parseInt(b.order_sequence));
        setItems(data?.list);
    }, [])
    
    return (
        <>

            {

                <>
                    {
                        // data?.classes?.length > 0 || data?.series?.length > 0 || data?.categories?.length > 0 ?
                        data?.list?.length > 0 ?

                            <section className='px-2 xs:px-5 pt-6 pb-[38px] md:pt-8 md:pb-[100px]'>
                                <div className='2xl:max-w-2xl xl:max-w-xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto text-primaryDark'>
                                    <div className='py-4 sm:py-8 space-y-3 sm:space-y-4'>
                                        {
                                            items?.map((obj) => (
                                                <div key={obj.id}>
                                                    {
                                                        obj.type === 'category' && 
                                                        <DataItem category={true} key={obj.id} dataObj={obj} />
                                                    }
                                                    {
                                                        obj.type === 'series' &&
                                                        <DataItem series={true} key={obj.id} dataObj={obj} />
                                                    }
                                                    {
                                                        obj.type === 'class' &&
                                                        <DataItem classes={true} key={obj.id} dataObj={obj} />
                                                    }
                                                </div>
                                            ))
                                        }
                                    </div>

                                    <div className='font-bold text-xl'>
                                        Share this category!
                                    </div>
                                    <div className='flex items-center gap-x-[27px] mt-3'>
                                        <WhatsappShareButton
                                            url={shareUrl}
                                            separator=":: "
                                            className="Demo__some-network__share-button"
                                        >
                                            <WhatsappIcon size={32} round />
                                        </WhatsappShareButton>
                                        <FacebookShareButton
                                            url={shareUrl}
                                            className="Demo__some-network__share-button"
                                        >
                                            <FacebookIcon size={32} round />
                                        </FacebookShareButton>

                                        <TwitterShareButton
                                            url={shareUrl}
                                            className="Demo__some-network__share-button"
                                        >
                                            <XIcon size={32} round />
                                        </TwitterShareButton>
                                    </div>
                                </div>
                            </section>
                            :
                            <div className='text-primaryDark text-lg font-bold text-center pt-10 h-[50vh]'>
                                No Data Found
                            </div>
                    }
                </>
            }

        </>
    )
}

export default UserCategory