import React, { useEffect, useState } from 'react'
import { ColorRing } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import Header from '../../header/Header';
import Banner from '../../banner/Banner';
import { GetCurrentMonth } from '../../../service/service';
import Footer from '../../footer/Footer';
import DataItem from '../../../ReuseableComponents/DataItem';

function MonthlyClasses() {
  const [data, setdata] = useState({});
  const [loading, setLoading] = useState(false)
  const [monthName ,setmonthName]= useState('')
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);

    // Optionally, you can also add a listener for navigation changes
    const handleScrollToTop = () => {
      window.scrollTo(0, 0);
    };

    // Attach the listener to the "beforeunload" event
    window.addEventListener('beforeunload', handleScrollToTop);

    // Clean up the listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleScrollToTop);
    };
  }, []);
  useEffect(() => {
    setLoading(true)
    GetCurrentMonth().then((result) => {
      setLoading(false)
      if (result.status) {
        setdata(result.data)
        setmonthName(result.data?.month)
      }
    })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  return (
    <>
      <Header />
      <Banner title={'JEWISH AND CHASSIDIC CALENDAR / '+monthName } />
      {
        loading ?
          <div className='flex justify-center min-h-[40vh]'>
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={['#D2D6DC', '#D2D6DC', '#D2D6DC', '#D2D6DC', '#D2D6DC']}
            />
          </div>
          :
          <>
            {
              data ?
                <section className='px-2 xs:px-5 pt-6 pb-[38px] md:pt-8 md:pb-[100px]'>
                  <div className='2xl:max-w-2xl xl:max-w-xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto text-primaryDark'>
                    <div className='py-4 sm:py-8 space-y-3 sm:space-y-4'>
                      {
                        data?.category?.map((obj) => (
                          <DataItem 
                            monthlyClasses={true} 
                            category={true} 
                            key={obj.id} 
                            dataObj={obj} 
                          />
                        ))
                      }

                    </div>
                  </div>
                </section>
                :
                <div className='text-primaryDark text-lg font-bold text-center pt-10 h-[50vh]'>
                  No Data Found
                </div>
            }
          </>
      }
      <Footer />
    </>
  )
}

export default MonthlyClasses