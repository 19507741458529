import {
  PauseIcon,
  PlayIcon
} from '../assets/icon-file';

export const PlayButtonComputer = ({modal, playPauseToggle, isPlaying}) => {
  const dimensions = 'w-[41px] h-[41px]';
  return (
    <button className={`${modal ? 'hidden' : 'xs:hidden sm:inline text-2xl'}`} onClick={playPauseToggle}>
        {isPlaying ? <PauseIcon dimensions={dimensions} /> : <PlayIcon dimensions={dimensions} />}
    </button>
  )
}

// The embed modal uses this button
export const PlayButtonMobile = ({modal, playPauseToggle, isPlaying}) => {
  const dimensions = `${modal ? 'w-8 h-8 lg:w-[41px] lg:h-[41px]' : 'w-[41px] h-[41px]'}`;
  return (
    <button className={`${modal ? 'text-2xl' : 'sm:hidden text-2xl'} `} onClick={playPauseToggle}>
        {isPlaying ? <PauseIcon dimensions={dimensions} /> : <PlayIcon dimensions={dimensions} />}
    </button>
  )
}

export const PlayInputComputer = ({modal, duration,setCurrentTime, handleDragEnd, handleDragStart, currentTime, formatTime}) => {
  return (
    <div className={`${modal ? 'hidden' : 'xs:hidden sm:flex flex-col flex-1 relative bottom-1' } `}>
        <input
            type="range"
            className='w-full cursor-pointer h-1'
            min="0"
            max={duration}
            step="1"
            onChange={(e) => setCurrentTime(parseFloat(e.target.value))}
            onMouseUp={(e) => handleDragEnd(e)}
            onTouchEnd={(e) => handleDragEnd(e)}
            onMouseDown={handleDragStart}
            onTouchStart={handleDragStart}
            value={currentTime}
        />
        <div className='flex absolute top-3 w-full justify-between text-xs'>
            <span>{formatTime(currentTime)}</span> <span>{formatTime(duration)}</span>
        </div>
    </div>
  )
}

export const PlayInputMobile = ({modal, duration,setCurrentTime, handleDragEnd, handleDragStart, currentTime, formatTime}) => {
  return (
    <div className={`${ modal ? 'flex flex-col flex-1 relative w-full' : 'xs:flex sm:hidden flex-col flex-1 relative w-full'}`}>
        <input
            type="range"
            className='w-full cursor-pointer h-1'
            min="0"
            max={duration}
            step="1"
            onChange={(e) => setCurrentTime(parseFloat(e.target.value))}
            onMouseUp={(e) => handleDragEnd(e)}
            onTouchEnd={(e) => handleDragEnd(e)}
            onMouseDown={handleDragStart}
            onTouchStart={handleDragStart}
            value={currentTime}
        />
        <div className='flex justify-between absolute w-full top-3 lg:top-6 text-xs'>
            <span>{formatTime(currentTime)}</span> <span>{formatTime(duration)}</span>
        </div>
    </div>
  )
}