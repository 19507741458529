import {
    useEffect,
    useState,
    Fragment
} from 'react'
import { Menu, Transition, Disclosure, Popover, Dialog } from '@headlessui/react';
import logo from "../../assets/logo.svg"
import profile from "../../assets/profile.svg"
import downarrow from "../../assets/Arrow Drop Down.svg"
import bookmark from "../../assets/Bookmark.svg"
import {
    XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Link, useNavigate } from "react-router-dom";
import { LogoutUser, showClassTabData } from '../../service/service';
import SearchModal from './SearchModal';
import { useDispatch } from 'react-redux';
import { logoutUserData } from '../../store/slices/UserDataSlice';
import { ProfileIcon } from '../../assets/icon-file';



export default function Header() {
    const navigate = useNavigate();
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [isLoggedin, setIsLoggedin] = useState(false);
    const [userData, setUserData] = useState({});
    const [classesData, setClassesData] = useState([]);
    const [searchOpen, setSearchOpen] = useState(false)
    const dispatch = useDispatch();

    useEffect(() => {
        showClassTabData().then((result) => {
            if (result.status) {
              
                setClassesData(result.data)
            }
        })
    }, [])

    function SignOutIcon(props) {
        return (

            <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
            </svg>

        )
    }

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const classes = [
        {
            id: 1,
            name: `Parsha`,
            href: '#',
            sub_category: [
                {
                    id: 7,
                    name: `Sefer Breishis`,
                    href: '#'
                },
                {
                    id: 8,
                    name: `Sefer Shemos`,
                    href: '#'
                },
                {
                    id: 9,
                    name: `Sefer Vayikra`,
                    href: '#'
                },
                {
                    id: 10,
                    name: `Sefer Bamidbar`,
                    href: '#'
                },
                {
                    id: 11,
                    name: `Sefer Devarim`,
                    href: '#'
                },

            ]
        },
        {
            id: 2,
            name: `Tanya`,
            href: '#',
            sub_category: [
                {
                    id: 9,
                    name: `Sefer Vayikra`,
                    href: '#'
                },
                {
                    id: 10,
                    name: `Sefer Bamidbar`,
                    href: '#'
                },
                {
                    id: 11,
                    name: `Sefer Devarim`,
                    href: '#'
                },
            ]
        },
        {
            id: 3,
            name: `Hayom Yom`,
            href: '#'
        },
        {
            id: 4,
            name: `Ma’amarim`,
            href: '#'
        },
        {
            id: 5,
            name: `Tefilla (Prayer)`,
            href: '#'
        },
        {
            id: 6,
            name: `Jewish and Chassidic Calendar`,
            href: '#'
        },

    ]
    const quickLinks = [
        {
            name: 'Q&A',
            href: '#'
        },
        {
            name: 'Chassidic Glossary',
            href: '#'
        },
        {
            name: 'Talks',
            href: '#'
        },
        {
            name: 'Kabbalah',
            href: '#'
        },
        {
            name: 'Rebbeim',
            href: '#'
        },
    ]
    const handleSignOut = () => {
        const token = localStorage.getItem('accessToken');
        LogoutUser(token).then(() => {
            // console.log(result)
            localStorage.removeItem('accessToken');
            localStorage.removeItem('loginData');
            dispatch(logoutUserData())
            navigate('/')
            window.location.reload();
        })
            .catch((error) => {
                console.log(error.message);

            });

    };
    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        if (token) {
            setIsLoggedin(true)
        }
        else {
            setIsLoggedin(false)
        }
        setUserData(JSON.parse(localStorage.getItem('loginData')))
    }, [])
    const OpenSearchModal = () => {
        setMobileMenuOpen(false)
        // setSearchOpen(true)
        navigate('/search')
    }
    return (
        <header id='main-header' className={`bg-primaryDark ${!mobileMenuOpen && 'z-50'}`}>
            <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:py-[18px] lg:px-4 xl:px-8" aria-label="Global">
                <div className="flex lg:flex-1 z-[1]">
                    <Link
                        to="/"
                        className="-m-1.5 p-1.5"
                    >
                        <span className="sr-only">Inside Cassidus</span>
                        <img className="h-11 w-48" src={logo} alt="Inside Cassidus" />
                    </Link>
                </div>
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                        onClick={() => setMobileMenuOpen(true)}
                    >
                        <span className="sr-only">Open main menu</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="20" viewBox="0 0 25 20" fill="none">
                            <rect width="18.4868" height="2.77302" rx="1.38651" fill="#F9F9F9" />
                            <rect x="6.47021" y="8.31982" width="17.5624" height="2.77302" rx="1.38651" fill="#F9F9F9" />
                            <rect y="16.6382" width="18.4868" height="2.77302" rx="1.38651" fill="#F9F9F9" />
                        </svg>
                    </button>
                </div>
                <Popover.Group className="hidden lg:flex lg:gap-x-5 xl:gap-x-8 px-4">
                    <Link
                        to="/"
                        className="text-base font-semibold leading-normal text-whiteGrey flex items-center"
                    >
                        Home
                    </Link>

                    <div className='group'>
                        <Link to="#" className="text-base font-semibold leading-normal text-whiteGrey flex items-center">
                            Classes
                        </Link>
                        <div className='bg-primaryDark text-whiteGrey w-full  max-h-[90vh] overflow-y-auto right-0 top-[52px] absolute hidden group-hover:block pt-11 pb-8'>
                            <ul className='flex relative mx-auto max-w-7xl lg:px-4 xl:px-8 flex-col gap-x-52 lg:gap-x-72 min-h-[80vh]  max-h-full'>
                                {
                                    classesData.map((obj) => (
                                        <li key={obj.id} className='flex items-baseline py-4 pl-2 hover:border-l-[3px] hover:border-primaryBlue class-tab'>
                                            {/* <Link to={`/${obj.slug}`} className="text-[18px] font-bold leading-7 hover:text-primaryBlue text-whiteGrey flex items-center">
                                                {obj.name}
                                            </Link> */}
                                            <a href={`/${obj.slug}`}
                                            className="text-[18px] font-bold leading-7 hover:text-primaryBlue text-whiteGrey flex items-center dd"
                                            >
                                            {obj.name}
                                            </a>
                                            <ul className='absolute h-full top-0 left-[40%] hidden mx-auto max-w-7xl lg:px-4 xl:px-8 flex-col gap-y-6 gap-x-52 lg:gap-x-72  max-h-full'>
                                                {
                                                    obj.sub_menu?.map((item,index) => (
                                                        <li key={'item-'+index}>
                                                            {/* <Link to={`/${item.slug}`} className="text-[18px] font-bold leading-7 hover:text-primaryBlue text-whiteGrey flex items-center">
                                                                {item.name}
                                                            </Link> */}
                                                            <a href={`/${obj.slug+'/'+item.slug}`}
                                                            className="text-[18px] font-bold leading-7 hover:text-primaryBlue text-whiteGrey flex items-center"
                                                            >
                                                            {item.name}
                                                            </a>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </li>
                                    ))
                                }

                            </ul>
                        </div>
                    </div>
                    <Link to="/about-us" className="text-base font-semibold leading-normal text-whiteGrey flex items-center">
                        About Us
                    </Link>
                    <Link to="/contact-us" className="text-base font-semibold leading-normal text-whiteGrey flex items-center">
                        Contact Us
                    </Link>

                    {/* <div className='group'>
                        <Link to="#" className="text-base font-semibold leading-normal text-whiteGrey flex items-center">
                            Quick Links
                        </Link>
                        <div className='bg-primaryDark text-whiteGrey w-full h-[345px] right-0 top-[52px] absolute hidden group-hover:block pt-11 pb-8'>
                            <ul className='flex mx-auto max-w-7xl lg:px-4 xl:px-8 flex-col gap-y-8 gap-x-52 lg:gap-x-72 flex-wrap max-h-full'>

                                {
                                    quickLinks.map((obj) => (
                                        <li key={obj.name}>
                                            <Link to={obj.href} className="text-[18px] font-bold leading-7 hover:text-primaryBlue text-whiteGrey flex items-center">
                                                {obj.name}
                                            </Link>
                                            <ul>

                                            </ul>
                                        </li>
                                    ))
                                }

                            </ul>
                        </div>
                    </div> */}
                    <Link to="/donations" className="text-base font-semibold leading-normal text-whiteGrey flex items-center">
                        Donate
                    </Link>
                </Popover.Group>

                <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                    {/* <div className='flex items-center mr-8 z-0'>
                        <Link to='/live' className='text-base font-semibold leading-normal text-whiteGrey'>
                            <div className='bg-[#FF4242] rounded-3xl inline-flex items-center gap-x-2 py-1 px-3'>

                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 fill-whiteGrey">
                                    <path fillRule="evenodd" d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z" clipRule="evenodd" />
                                </svg>


                                Live
                            </div>
                        </Link>
                    </div> */}
                    <div onClick={OpenSearchModal} className='flex items-center mr-4 xl:mr-6 cursor-pointer'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-7 h-7 fill-whiteGrey">
                            <path fillRule="evenodd" d="M10.5 3.75a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zM2.25 10.5a8.25 8.25 0 1114.59 5.28l4.69 4.69a.75.75 0 11-1.06 1.06l-4.69-4.69A8.25 8.25 0 012.25 10.5z" clipRule="evenodd" />
                        </svg>

                    </div>
                    {
                        isLoggedin ?

                            <>

                                <div  onClick={() => navigate('/user-bookmarks')} className='cursor-pointer flex items-center mr-4  xl:mr-6'>
                                    <img src={bookmark} alt="bookmark" />
                                </div>

                                <>
                                    <div className="relative flex">
                                        <Menu>
                                            {({ open }) => (
                                                <>
                                                    <Menu.Button >
                                                        <div className='flex items-center justify-center'>
                                                            <div className='flex items-center'>
                                                                <img src={profile} alt="prifile icon" />
                                                            </div>
                                                            <div className='flex items-center'>
                                                                <img src={downarrow} alt="prifile icon" />
                                                            </div>
                                                        </div>
                                                    </Menu.Button>

                                                    {open && (
                                                        <Transition
                                                            as={Fragment}
                                                            enter="transition ease-out duration-100"
                                                            enterFrom="transform opacity-0 scale-95"
                                                            enterTo="transform opacity-100 scale-100"
                                                            leave="transition ease-in duration-75"
                                                            leaveFrom="transform opacity-100 scale-100"
                                                            leaveTo="transform opacity-0 scale-95"
                                                        >
                                                            <Menu.Items
                                                                static
                                                                className="z-10 bg-white border w-56 border-gray-300 absolute right-0 top-8 mt-2 rounded-md shadow-lg focus:outline-none"
                                                            >
                                                                <div className="px-1 py-1">
                                                                    <Menu.Item>
                                                                        {({ active }) => (
                                                                            <button
                                                                                className={`${active ? 'bg-primaryDark text-whiteGrey' : 'text-primaryDark'
                                                                                    } group flex w-full items-center rounded-md px-2 py-2 text-base font-semibold`}
                                                                                onClick={handleSignOut}
                                                                            >
                                                                                {active ? (
                                                                                    <SignOutIcon
                                                                                        className="mr-2 h-5 w-5 text-whiteGrey"
                                                                                        aria-hidden="true"
                                                                                    />
                                                                                ) : (
                                                                                    <SignOutIcon
                                                                                        className="mr-2 h-5 w-5"
                                                                                        aria-hidden="true"
                                                                                    />
                                                                                )}
                                                                                Sign Out
                                                                            </button>

                                                                        )}
                                                                    </Menu.Item>
                                                                    <Menu.Item>
                                                                        {({ active }) => (
                                                                            <button
                                                                                className={`${active ? 'bg-primaryDark text-whiteGrey' : 'text-primaryDark'
                                                                                    } group flex w-full items-center rounded-md px-2 py-2 text-base font-semibold`}
                                                                                onClick={()=>navigate('/profile')}
                                                                            >
                                                                                {active ? (
                                                                                    <ProfileIcon
                                                                                        className="mr-2 h-5 w-5 text-whiteGrey"
                                                                                        aria-hidden="true"
                                                                                    />
                                                                                ) : (
                                                                                    <ProfileIcon
                                                                                        className="mr-2 h-5 w-5 text-primaryDark"
                                                                                        aria-hidden="true"
                                                                                    />
                                                                                )}
                                                                                Profile
                                                                            </button>

                                                                        )}
                                                                    </Menu.Item>
                                                                  
                                                                </div>
                                                            </Menu.Items>
                                                        </Transition>
                                                    )}
                                                </>
                                            )}
                                        </Menu>
                                    </div>
                                </>
                            </>
                            :
                            <Link to="/sign-in" className="text-sm font-bold whitespace-nowrap text-whiteGrey bg-primaryBlue hover:bg-opacity-[0.9] px-[32px] py-[14px] rounded-3xl z-[1]">
                                Sign in
                            </Link>
                    }

                </div>
            </nav>
            <SearchModal
                searchOpen={searchOpen}
                setSearchOpen={setSearchOpen}
            />
            <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                <div className="fixed inset-0 z-10" />
                <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-primaryDark px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">

                        <Link
                            to="/"
                            className="-m-1.5 p-1.5"
                        >
                            <span className="sr-only">Inside Cassidus</span>
                            <img
                                className="h-8 w-auto"
                                src={logo}
                                alt="Inside Cassidus"
                            />
                        </Link>
                        <div className='flex items-center xs:gap-3'>
                            {/* <div className='bg-[#FF4242] rounded-3xl inline-flex items-center gap-x-2 py-1 px-3'>

                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 fill-whiteGrey">
                                    <path fillRule="evenodd" d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z" clipRule="evenodd" />
                                </svg>


                                <Link to="/live" className='text-base font-semibold leading-normal text-whiteGrey'>
                                    Live
                                </Link>
                            </div> */}
                            <button
                                type="button"
                                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                                onClick={() => setMobileMenuOpen(false)}
                            >
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon className="h-6 w-6 text-whiteGrey" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6">
                            {
                                isLoggedin &&
                                <div className="pt-6">
                                    <div
                                    onClick={()=>navigate('/profile')}
                                    className='flex items-center gap-3 text-whiteGrey cursor-pointer'>
                                        <img src={profile} alt="prifile icon" />
                                        {userData?.name}
                                    </div>
                                </div>
                            }
                            <div className='pt-6'>
                                <div onClick={OpenSearchModal} className='relative flex items-center justify-between gap-2 px-8 py-[14px] h-12  border-gray-500 border-[1px] rounded-3xl'>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path opacity="0.8" d="M23.6057 21.713L19.0739 17.1945C20.536 15.3317 21.3294 13.0313 21.3265 10.6632C21.3265 8.55425 20.7011 6.49262 19.5294 4.73906C18.3577 2.9855 16.6923 1.61877 14.7439 0.811695C12.7954 0.00462018 10.6514 -0.206547 8.58294 0.204896C6.51448 0.616339 4.61447 1.63191 3.12319 3.12319C1.63191 4.61447 0.616339 6.51448 0.204896 8.58294C-0.206547 10.6514 0.00462018 12.7954 0.811695 14.7439C1.61877 16.6923 2.9855 18.3577 4.73906 19.5294C6.49262 20.7011 8.55425 21.3265 10.6632 21.3265C13.0313 21.3294 15.3317 20.536 17.1945 19.0739L21.713 23.6057C21.8369 23.7307 21.9843 23.8298 22.1468 23.8975C22.3092 23.9652 22.4834 24 22.6594 24C22.8353 24 23.0095 23.9652 23.172 23.8975C23.3344 23.8298 23.4818 23.7307 23.6057 23.6057C23.7307 23.4818 23.8298 23.3344 23.8975 23.172C23.9652 23.0095 24 22.8353 24 22.6594C24 22.4834 23.9652 22.3092 23.8975 22.1468C23.8298 21.9843 23.7307 21.8369 23.6057 21.713ZM2.66581 10.6632C2.66581 9.08149 3.13485 7.53527 4.01362 6.2201C4.89239 4.90494 6.14142 3.87989 7.60275 3.27458C9.06409 2.66927 10.6721 2.5109 12.2235 2.81948C13.7748 3.12806 15.1998 3.88974 16.3183 5.0082C17.4367 6.12666 18.1984 7.55167 18.507 9.10301C18.8156 10.6544 18.6572 12.2624 18.0519 13.7237C17.4466 15.1851 16.4215 16.4341 15.1064 17.3128C13.7912 18.1916 12.245 18.6607 10.6632 18.6607C8.54219 18.6607 6.50801 17.8181 5.0082 16.3183C3.5084 14.8185 2.66581 12.7843 2.66581 10.6632Z" fill="#D2D6DC" />
                                        </svg>
                                    </span>
                                    <input className='w-full bg-transparent outline-none text-whiteGrey' placeholder='Search' type="text" />
                                </div>
                            </div>
                            <div className="space-y-2 py-6">
                                <Disclosure as="div" className="-mx-3">
                                    {({ open }) => (
                                        <>
                                            <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-whiteGrey hover:text-primaryDark hover:bg-gray-50">
                                                Classes
                                                <ChevronDownIcon
                                                    className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                                                    aria-hidden="true"
                                                />
                                            </Disclosure.Button>
                                            <Disclosure.Panel className="mt-2 space-y-2">
                                                {classesData?.map((item) => (
                                                    <Disclosure.Button
                                                        key={item.id}
                                                        as="a"
                                                        href={item.slug}
                                                        className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-whiteGrey hover:text-primaryDark hover:bg-gray-50"
                                                    >
                                                        {item.name}
                                                    </Disclosure.Button>
                                                ))}
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>
                                <Link to="/about-us" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-whiteGrey hover:text-primaryDark hover:bg-gray-50">
                                    About Us
                                </Link>
                                <Link to="/contact-us" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-whiteGrey hover:text-primaryDark hover:bg-gray-50">
                                    Contact Us
                                </Link>
                                {/* <Disclosure as="div" className="-mx-3">
                                    {({ open }) => (
                                        <>
                                            <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-whiteGrey hover:text-primaryDark hover:bg-gray-50">
                                                Quick links
                                                <ChevronDownIcon
                                                    className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                                                    aria-hidden="true"
                                                />
                                            </Disclosure.Button>
                                            <Disclosure.Panel className="mt-2 space-y-2">
                                                {[...quickLinks].map((item) => (
                                                    <Disclosure.Button
                                                        key={item.name}
                                                        as="a"
                                                        href={item.href}
                                                        className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-whiteGrey hover:text-primaryDark hover:bg-gray-50"
                                                    >
                                                        {item.name}
                                                    </Disclosure.Button>
                                                ))}
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure> */}
                                <Link to="#" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-whiteGrey hover:text-primaryDark hover:bg-gray-50">
                                    Donate
                                </Link>
                            </div>
                            {
                                isLoggedin ?
                                    <div className='px-3'>
                                        <button onClick={handleSignOut} className="-mx-3 border-[#F4FBF7] border-[1px] text-sm font-bold  text-whiteGrey px-[32px] py-[14px] rounded-3xl">
                                            Sign Out
                                        </button>
                                    </div>
                                    :
                                    <div className='px-3'>
                                        <Link to="/sign-in" className="-mx-3 text-sm font-bold text-whiteGrey bg-primaryBlue px-[32px] py-[14px] rounded-3xl">
                                            Sign in
                                        </Link>
                                    </div>
                            }
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </header>
    )
}
