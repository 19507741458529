import React, { useState, useEffect } from 'react'
import Header from '../../components/header/Header'
import Banner from '../../components/banner/Banner'
import { ColorRing } from 'react-loader-spinner';
import { ViewbookMark } from '../../service/service';
import Footer from '../../components/footer/Footer';
import { useSelector } from 'react-redux';
import DataItem from '../../ReuseableComponents/DataItem';

function Bookmark() {
    const userData = useSelector((state) => state.userdata.userdata)
    const [isLoggedin, setIsLoggedin] = useState(false)
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false)
    const [bookmarkLoading, setBookmarkLoading] = useState({ loadingId: null });
    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
    
        // Optionally, you can also add a listener for navigation changes
        const handleScrollToTop = () => {
          window.scrollTo(0, 0);
        };
    
        // Attach the listener to the "beforeunload" event
        window.addEventListener('beforeunload', handleScrollToTop);
    
        // Clean up the listener when the component unmounts
        return () => {
          window.removeEventListener('beforeunload', handleScrollToTop);
        };
      }, []);

    useEffect(() => {

        setLoading(true)
        ViewbookMark(data).then((result) => {
            setLoading(false)
            if (result.status) {
                // console.log(result.data[0])
                setData(result.data)

            }
        })
        if (userData) {

            setIsLoggedin(true)
        }
        else {
            setIsLoggedin(false)
        }
    }, [])

    return (
        <>
            <Header />
            <Banner title={'Bookmarks'} />
            {
                loading ?
                    <div className='flex justify-center min-h-[40vh]'>
                        <ColorRing
                            visible={true}
                            height="80"
                            width="80"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                            colors={['#D2D6DC', '#D2D6DC', '#D2D6DC', '#D2D6DC', '#D2D6DC']}
                        />
                    </div>
                    :
                    <>
                        {
                            data?.classes?.length > 0 || data?.series?.length > 0 || data?.categories?.length > 0 ?

                                <section className='px-2 xs:px-5 pt-6 pb-[38px] md:pt-8 md:pb-[100px] min-h-[40vh]'>
                                    <div className='2xl:max-w-2xl xl:max-w-xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto text-primaryDark'>
                                        {
                                            data?.categories?.length > 0 &&
                                            <>
                                                <div className='text-[22px] leading-[normal] md:text-2xl font-extrabold'>
                                                    Categories
                                                </div>
                                                <div className='py-4 sm:py-8 space-y-3 sm:space-y-4'>
                                                    {
                                                        data?.categories?.map((obj) => (
                                                            <DataItem
                                                                bookmarks={true}
                                                                category={true} 
                                                                key={obj.id}
                                                                dataObj={obj}
                                                                prevData={data}
                                                                data={data.categories}
                                                                setData={setData} 
                                                                bookmarkLoading={bookmarkLoading} 
                                                                setBookmarkLoading={setBookmarkLoading} 
                                                                isLoggedin={isLoggedin} 
                                                            />
                                                        ))
                                                    }
                                                </div>
                                            </>
                                        }
                                        {
                                            data?.classes?.length > 0 &&
                                            <>
                                                <div className='text-[22px] leading-[normal] md:text-2xl font-extrabold'>
                                                    Classes
                                                </div>
                                                <div className='py-4 sm:py-8 space-y-3 sm:space-y-4'>
                                                    {
                                                        data?.classes?.map((obj) => (
                                                            <DataItem
                                                                bookmarks={true}
                                                                classes={true} 
                                                                key={obj.id}
                                                                dataObj={obj}
                                                                prevData={data}
                                                                data={data.classes}
                                                                setData={setData} 
                                                                bookmarkLoading={bookmarkLoading} 
                                                                setBookmarkLoading={setBookmarkLoading} 
                                                                isLoggedin={isLoggedin} 
                                                            />
                                                        ))
                                                    }
                                                </div>
                                            </>
                                        }
                                        {
                                            data?.series?.length > 0 &&
                                            <>
                                                <div className='text-[22px] leading-[normal] md:text-2xl font-extrabold'>
                                                    Series
                                                </div>
                                                <div className='py-4 sm:py-8 space-y-3 sm:space-y-4'>
                                                    {
                                                        data?.series?.map((obj) => (
                                                            <DataItem
                                                                series={true} 
                                                                key={obj.id}
                                                                dataObj={obj}
                                                                data={data?.series}
                                                                prevData={data}
                                                                setData={setData} 
                                                                bookmarkLoading={bookmarkLoading} 
                                                                setBookmarkLoading={setBookmarkLoading} 
                                                                isLoggedin={isLoggedin} 
                                                            />
                                                        ))
                                                    }
                                                </div>
                                            </>
                                        }
                                    </div>
                                </section>
                                :
                                <div className='text-primaryDark text-lg font-bold text-center pt-10 h-[50vh]'>
                                    No Data Found
                                </div>
                        }
                    </>
            }
            <Footer />
        </>
    )
}

export default Bookmark