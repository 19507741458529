import React, { useState } from 'react'
import Header from './Header'
import { Disclosure } from '@headlessui/react'
import { AdvanceUserSearch, UserSearch } from '../../service/service';
import { ColorRing } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { XMarkIcon } from '@heroicons/react/24/outline';
import DataItem from '../../ReuseableComponents/DataItem';
function SearchPage() {
  const navigate = useNavigate();
  const [inputText, setInputText] = useState('');
  const [loading, setloading] = useState(false);
  const [classes, setClassess] = useState([]);
  const [series, setSeries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [flag, setFlag] = useState(false)
  const [inputValue, setInputValue] = useState('');
  const [chips, setChips] = useState([]);
  const [formData, setFormData] = useState({
    category: 1,
    series: 1,
    classes: 1,
  });
  const [isOpen, setIsOpen] = useState(false)
  const checkOpen = (open) => {

    setIsOpen(open)
  }
  const HandleSearch = () => {

    setloading(true)
    setFlag(true)
    if (isOpen) {
      const data = { ...formData, tag: chips }
      AdvanceUserSearch(data)
        .then((result) => {
          setloading(false)
          // console.log(result.data)
          setClassess(result.data?.classes?.data ?? []);
          setSeries(result.data?.series?.data ?? []);
          setCategories(result.data?.category.data ?? []);
        });
    }
    else {
      const data = { search: inputText };

      UserSearch(data)
        .then((result) => {
          setloading(false)
          // console.log(result.data)
          setClassess(result.data?.classes?.data ?? []);
          setSeries(result.data?.series?.data ?? []);
        });
    }
    // console.log(formData)
  }

  const viewAll = (param) => {
    // console.log(param)
    const data = {
      type: param,
      query: isOpen ? chips.join(',') : inputText,
      classes,
      series,
      categories,
    }

    navigate('/view-all', { state: { data } });
  }
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      // Function to call on 'Enter' key press
      HandleSearch()
    }
  };



  const handleInputChange = (event) => {
    const { name, type, checked, value } = event.target;
    const newValue = type === 'checkbox' ? checked : value;
    if (type === 'checkbox') {

      setFormData((prevData) => ({
        ...prevData,
        [name]: newValue ? 1 : 0,
      }));
    }
    else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: newValue,
      }));
    }
  };


  const handleInputChange2 = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      const newChip = inputValue.trim();
      setChips([...chips, newChip]);
      setInputValue('');
    }
    else if (event.key === 'Backspace' && inputValue === '' && chips.length > 0) {
      const updatedChips = chips.slice(0, -1); // Remove the most recent chip
      setChips(updatedChips);
    }
  };

  const handleChipDelete = (chipToDelete) => {
    const updatedChips = chips.filter(chip => chip !== chipToDelete);
    setChips(updatedChips);
  };
  const handleClearAll = () => {
    setChips([]);
  };
  return (
    <div>
      <Header />
      <section className='px-2 xs:px-5 pt-6 pb-[38px] md:pt-8 md:pb-[100px]'>
        <div className='2xl:max-w-2xl xl:max-w-xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto text-primaryDark'>
          <div className='flex flex-col sm:flex-row justify-between items-center gap-2 md:gap-6'>
          {
            !isOpen &&
              <div className='relative self-baseline flex w-full flex-1 items-center justify-between gap-2 px-8 py-[14px] h-12 bg-[#D2D6DC] bg-opacity-[0.4]   rounded-3xl'>
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M8.80039 2.20002C7.04996 2.20002 5.37123 2.89537 4.13349 4.13311C2.89575 5.37085 2.20039 7.04959 2.20039 8.80002C2.20039 10.5504 2.89575 12.2292 4.13349 13.4669C5.37123 14.7047 7.04996 15.4 8.80039 15.4C10.5508 15.4 12.2296 14.7047 13.4673 13.4669C14.705 12.2292 15.4004 10.5504 15.4004 8.80002C15.4004 7.04959 14.705 5.37085 13.4673 4.13311C12.2296 2.89537 10.5508 2.20002 8.80039 2.20002ZM0.400391 8.80002C0.400504 7.45682 0.722725 6.13324 1.34003 4.94029C1.95733 3.74735 2.8517 2.71983 3.94813 1.94393C5.04457 1.16804 6.31108 0.666387 7.64143 0.481064C8.97178 0.295741 10.3272 0.432149 11.5939 0.878846C12.8607 1.32554 14.0018 2.0695 14.9216 3.04832C15.8415 4.02713 16.5132 5.21226 16.8804 6.50429C17.2476 7.79631 17.2996 9.15756 17.0321 10.4738C16.7645 11.7901 16.1852 13.0231 15.3428 14.0692L19.3364 18.064C19.4248 18.1464 19.4957 18.2458 19.5449 18.3562C19.5941 18.4666 19.6206 18.5857 19.6227 18.7066C19.6248 18.8274 19.6026 18.9475 19.5573 19.0595C19.5121 19.1716 19.4447 19.2734 19.3592 19.3589C19.2738 19.4443 19.172 19.5117 19.0599 19.557C18.9478 19.6022 18.8278 19.6245 18.707 19.6223C18.5861 19.6202 18.4669 19.5937 18.3565 19.5446C18.2461 19.4954 18.1468 19.4244 18.0644 19.336L14.0696 15.3424C12.8354 16.3365 11.3453 16.9612 9.7712 17.1443C8.19712 17.3274 6.60334 17.0616 5.17393 16.3774C3.74452 15.6933 2.53779 14.6188 1.69312 13.2779C0.848446 11.9371 0.400283 10.3847 0.400391 8.80002Z" fill="#515F7B" />
                </svg>
              </span>
              <input
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                onKeyDown={handleKeyPress}
                className='w-full bg-transparent outline-none' placeholder='Search...' type="text" />
            </div>
          }
          {
            isOpen &&
            <>
            
            <div className='flex w-full flex-1 justify-between items-center pb-5 gap-6 flex-col md:flex-row'>
              <div className='flex w-full flex-1 items-center gap-2 md:gap-5 lg:gap-14'>
                <div className='flex gap-2'>
                  <input
                    checked={formData.category === 1}
                    onChange={handleInputChange}
                    className='h-7 w-7' name={`category`} type="checkbox" id={`item-category`} />
                  <label className='text-lg font-semibold' htmlFor={`item-category`}>Categories</label>
                </div>
                <div className='flex gap-2'>
                  <input
                    checked={formData.series === 1}
                    onChange={handleInputChange}
                    className='h-7 w-7' name={`series`} type="checkbox" id={`item-series`} />
                  <label className='text-lg font-semibold' htmlFor={`item-series`}>Series</label>
                </div>
                <div className='flex gap-2'>
                  <input
                    checked={formData.classes === 1}
                    onChange={handleInputChange}
                    className='h-7 w-7' name={`classes`} type="checkbox" id={`item-classes`} />
                  <label className='text-lg font-semibold' htmlFor={`item-classes`}>Classes</label>
                </div>

              </div>
              <div className='min-h-[85px] w-full flex-1 rounded-lg bg-[#D2D6DC] bg-opacity-[0.4] pt-4 px-4'>
                <label htmlFor="tag-name" className="block text-sm font-normal leading-5 text-primaryDark">
                  Search by Tags/Slug
                </label>
                <div className='flex items-center justify-between'>
                <div className="flex flex-wrap">
                  {/* <div> */}
                  {chips.map((chip, index) => (
                    <div key={index} className="chip">
                      {chip}
                      <span
                        className="close-icon"
                        onClick={() => handleChipDelete(chip)}
                      >
                        &times;
                      </span>
                    </div>
                  ))}
                  {/* </div> */}
                  <input
                    id="tag-name"
                    name="tag"
                    type="text"
                    placeholder="Type and press Enter"
                    value={inputValue}
                    onChange={handleInputChange2}
                    onKeyDown={handleInputKeyDown}
                    className="block bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                  />
                  
                </div>
                <div>
                {chips.length > 0 && (
                    <button onClick={handleClearAll}><XMarkIcon class="h-6 w-6 text-gray-500" /></button>
                  )}
                </div>
                </div>
              </div>
              {/* <div className='w-[100px] md:w-[137px]'>

              </div> */}
            </div>
          </>
          }
          <div className='flex w-full sm:w-[unset] flex-col'>

            <button
              type="button"
              onClick={HandleSearch}
              className="flex gap-x-2 w-full sm:w-[100px] md:w-[137px] h-11 items-center justify-center rounded-3xl bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue  text-sm md:text-base font-normal leading-7 text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              Search
              <span aria-hidden="true" className='text-[13px]'>
                <svg className='relative right-[-1px]' width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.8086 12.8633C14.0547 13.1367 14.0547 13.5469 13.7812 13.793L13.0156 14.5586C12.7695 14.832 12.3594 14.832 12.0859 14.5586L9.37891 11.8516C9.24219 11.7148 9.1875 11.5508 9.1875 11.3867V10.9219C8.20312 11.6875 7 12.125 5.6875 12.125C2.54297 12.125 0 9.58203 0 6.4375C0 3.32031 2.54297 0.75 5.6875 0.75C8.80469 0.75 11.375 3.32031 11.375 6.4375C11.375 7.77734 10.9102 8.98047 10.1719 9.9375H10.6094C10.7734 9.9375 10.9375 10.0195 11.0742 10.1289L13.8086 12.8633ZM5.6875 9.9375C7.60156 9.9375 9.1875 8.37891 9.1875 6.4375C9.1875 4.52344 7.60156 2.9375 5.6875 2.9375C3.74609 2.9375 2.1875 4.52344 2.1875 6.4375C2.1875 8.37891 3.74609 9.9375 5.6875 9.9375Z" fill="white" />
                </svg>
              </span>
            </button>
            <Disclosure>
            {({ open }) => (
              <>
                <div className='flex items-center justify-end py-2'>
                  <Disclosure.Button
                    onClick={() => checkOpen(!open)}
                    className='text-sm md:text-base leading-[18px] font-semibold text-primaryBlue w-[110px] md:w-[137px] text-center cursor-pointer'>
                    Advance Search
                  </Disclosure.Button>
                </div>
               
              </>
            )}
          </Disclosure>
          </div>
          </div>
       
          <div>
            <hr />
          </div>
          <div className='py-8'>
            {
              loading ?
                <div className='flex justify-center'>
                  <ColorRing
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={['#D2D6DC', '#D2D6DC', '#D2D6DC', '#D2D6DC', '#D2D6DC']}
                  />
                </div>

                :
                <>
                  {
                    classes.length === 0 && series.length === 0 && categories.length === 0 ?
                      <div className='flex justify-center h-[50vh]'>
                        {flag ? 'No data found' : "Search for classes, series or categories"}
                      </div>
                      :
                      <>
                        {
                          classes.length > 0 &&
                          <>
                            <div className='text-[22px] leading-[normal] md:text-2xl font-extrabold'>
                              Classes
                            </div>
                            <div className='py-4 sm:py-8 space-y-3 sm:space-y-4'>
                              {
                                classes?.slice(0, 3)?.map((obj) => (
                                  <DataItem 
                                    searchPage={true} 
                                    classes={true} 
                                    key={obj.id} 
                                    dataObj={obj} 
                                  />
                                ))
                              }
                              {
                                classes.length > 3 &&
                                <div onClick={() => viewAll('class')} className='text-sm font-bold cursor-pointer pl-4 pt-4 text-primaryBlue'>View all</div>
                              }
                            </div>
                          </>
                        }
                        {
                          series.length > 0 &&

                          <>
                            <div className='text-[22px] leading-[normal] md:text-2xl font-extrabold'>
                              Series
                            </div>
                            <div className='py-4 sm:py-8 space-y-3 sm:space-y-4'>
                              {
                                series?.slice(0, 3)?.map((obj) => (
                                  <DataItem
                                    searchPage={true}
                                    series={true}
                                    key={obj.id}
                                    dataObj={obj}
                                  />
                                ))
                              }
                              {
                                 series.length > 3 &&
                              <div onClick={() => viewAll('series')} className='text-sm font-bold cursor-pointer pl-4 pt-4 text-primaryBlue'>View all</div>
                              }
                            </div>
                          </>
                        }
                        {
                          categories.length > 0 &&

                          <>
                            <div className='text-[22px] leading-[normal] md:text-2xl font-extrabold'>
                              Categories
                            </div>
                            <div className='py-4 sm:py-8 space-y-3 sm:space-y-4'>
                              {
                                categories?.slice(0, 3)?.map((obj) => (
                                  <DataItem
                                    searchPage={true}
                                    category={true}
                                    key={obj.id}
                                    dataObj={obj}
                                  />
                                ))
                              }
                              {
                                 categories.length > 3 &&

                              <div onClick={() => viewAll('category')} className='text-sm font-bold cursor-pointer pl-4 pt-4 text-primaryBlue'>View all</div>
                              }
                            </div>
                          </>
                        }
                      </>
                  }
                </>
            }
          </div>
        </div>
      </section>
    </div>
  )
}

export default SearchPage