import React from 'react'
import { useParams } from 'react-router-dom';
import Header from '../../components/header/Header';
import Banner from '../../components/banner/Banner';
import { useEffect } from 'react';
import { GetTagDataFromURL } from '../../service/service';
import { useState } from 'react';
import { ColorRing } from 'react-loader-spinner';
import Footer from '../../components/footer/Footer';
import NotFound from '../notfoundPage/NotFound';
import { Helmet } from 'react-helmet';
import InfiniteScroll from 'react-infinite-scroll-component';
import DataItem from '../../ReuseableComponents/DataItem';
// const shareUrl = window.location.href;
function SearchTags() {
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true)
    const { uniqueName } = useParams();
    const [items, setItems] = useState([]);
    const [title, setTitle] = useState("Inside Chassidus | Audio Classes | Chassidus for Everyone")
    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);

        // Optionally, you can also add a listener for navigation changes
        const handleScrollToTop = () => {
            window.scrollTo(0, 0);
        };

        // Attach the listener to the "beforeunload" event
        window.addEventListener('beforeunload', handleScrollToTop);

        // Clean up the listener when the component unmounts
        return () => {
            window.removeEventListener('beforeunload', handleScrollToTop);
        };
    }, []);
    useEffect(() => {
        fetchMoreData()
        setLoading(true)
    }, [uniqueName])
    const fetchMoreData = () => {
        const data = {
            // type: 'series',
            tag: uniqueName
        }

        GetTagDataFromURL(data, page).then((result) => {
            setLoading(false)
            if (result.status) {
                if (result.data[0].data.length === 0) {
                    setHasMore(false);
                }
                else {

                    setData(result.data)
                    setTitle(`${result.data[1]} | Inside Chassidus`)
                    setItems([...items, ...result.data[0].data]);
                    setPage(page + 1);
                }

            }
        }).catch((error) => {
            setLoading(false)
            console.log(error.message)
        })
    }

    return (
        <>
            <Header />
            <Banner title={data[1]} />
            <Helmet>
                <title>{title}</title>
            </Helmet>
            {
                loading ?
                    <div className='flex justify-center min-h-[50vh]'>
                        <ColorRing
                            visible={true}
                            height="80"
                            width="80"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                            colors={['#D2D6DC', '#D2D6DC', '#D2D6DC', '#D2D6DC', '#D2D6DC']}
                        />
                    </div>
                    :

                    <>
                        {
                            Object.keys(data).length !== 0 ?
                                // data!=={} ?
                                <>
                                    {
                                        items?.length > 0 ?

                                            <section className='px-2 xs:px-5 pt-6 pb-[38px] md:pt-8 md:pb-[100px]'>
                                                <div className='2xl:max-w-2xl xl:max-w-xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto text-primaryDark'>

                                                    <InfiniteScroll
                                                        dataLength={items.length}
                                                        next={fetchMoreData}
                                                        hasMore={hasMore}
                                                        loader={<h4 className='flex justify-center'><ColorRing
                                                            visible={true}
                                                            height="80"
                                                            width="80"
                                                            ariaLabel="blocks-loading"
                                                            wrapperStyle={{}}
                                                            wrapperClass="blocks-wrapper"
                                                            colors={['#D2D6DC', '#D2D6DC', '#D2D6DC', '#D2D6DC', '#D2D6DC']}
                                                        /></h4>}
                                                    >
                                                        <div className='py-4 sm:py-8 space-y-3 sm:space-y-4 min-h-[50vh]'>
                                                            {
                                                                items?.map((obj) => (
                                                                    <div key={obj.id}>
                                                                        <DataItem classes={true} key={obj.id} dataObj={obj} />
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </InfiniteScroll>

                                                </div>
                                            </section>
                                            :

                                            <div className='text-primaryDark text-lg font-bold text-center pt-10 h-[50vh]'>
                                                No Data To Show
                                            </div>
                                    }
                                </> :
                                <NotFound />
                        }

                    </>
            }
            <Footer />
        </>
    )
}

export default SearchTags