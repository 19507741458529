import React, { useEffect, useState } from 'react';
import img1 from './assets/dailytanya.jpeg'
import img2 from './assets/DailyHayomYom.jpeg'
import img3 from './assets/Parshaoftheweekresized.jpeg'
import img4 from './assets/Elulresized.jpeg'
import { GetAudianceOptions, GetCurrentMonth, UserSubscribe } from '../../../service/service';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import SubscribeModal from './SubscribeModal';
function CategorySection() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [monthName, setMonthName] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [emailList, setEmailList]=useState([])
    const [selected, setSelected] = useState([])
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };
    // useEffect(() => {
    //   console.log(selected)
    // }, [selected])
    
    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission (e.g., send email to backend)
        // console.log('Subscribed with email:', email);
        setLoading(true)
        GetAudianceOptions().then((result) => {
            setLoading(false)
            // if(result.status){
                const filteredArray = result.data?.filter(item => item.audience_name === "Inside Chassidus");
                const newData= filteredArray?.map(item=>{
                    return {
                        label: item.audience_name,
                        value:item.id
                    }
                })
                setEmailList(newData)
                setIsOpen(true)
            // }
        })
       

    };

    const submitSubscribe = (event) => {
        event.preventDefault();
        const data={
            email,
            id:selected.map(item => item.value)
        }
        
        setLoading(true)
        setIsOpen(false)
        UserSubscribe(data).then((result)=>{
            setLoading(false)
           
            if(result.status){
                setEmail('')
                Swal.fire({
                    icon: 'success',
                    title: "Subscribed Successfully.",
                    showConfirmButton: false,
                    timer: 1500
                })
            }
            else{
                Swal.fire({
                    icon: 'error',
                    title: result.message,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        }).catch((error)=>{
            setLoading(false)
            Swal.fire({
                icon: 'error',
                title: error.message,
                showConfirmButton: false,
                timer: 2000
            })
        })
    }
    const showDailyTanya = (event) => {
        event.preventDefault();
        navigate('/daily-tanya');
    }
    const showDailyHayomYom = (event) => {
        event.preventDefault();
        navigate('/daily-hayom-yom');
    }
    const showWeeklyParsh = (event) => {
        event.preventDefault();
        navigate('/weekly-parsha-classes');
    }
    const showMonthlyClasses = (event) => {
        event.preventDefault();
        navigate(`/monthly-classes`);
    }
    useEffect(() => {
        GetCurrentMonth().then((result) => {
            if (result.status) {
                // console.log(result.data)
                setMonthName(result.data?.month)
                // setMonthUrl(result.data?.category[0]?.slug)
            }
        }).catch((error) => {
            console.log(error)
        })
    }, [])

    return (
        <>
            <section id='category-section' className='px-2 xs:px-5 pb-8 sm:pb-[60px]'>
                <div className="2xl:max-w-2xl xl:max-w-xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto">
                    <div className='flex flex-col md:flex-row gap-4 md:gap-6'>
                        <div className='md:w-[58%]'>
                            <div className='flex  gap-x-4 md:gap-x-6 h-full'>
                                <div className='w-2/4 md:w-3/5'>
                                    <div className='h-[194px] md:h-[375px] rounded-2xl lg:rounded-[32px]' style={{
                                        background: `linear-gradient(180deg, rgba(41, 27, 13, 0.27) 0%, rgba(41, 27, 13, 0.90) 100%), url(${img1})`,
                                        backgroundPosition: 'center top',
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat'
                                    }}>
                                        <a href="/daily-tanya" onClick={(event) => showDailyTanya(event)} className='cursor-pointer text-xl xs:text-2xl lg:text-[32px] text-whiteGrey font-extrabold leading-[normal] h-full flex items-end p-3 lg:p-6'>
                                            Daily Tanya
                                        </a>
                                    </div>

                                </div>
                                <div className='w-2/4 md:w-2/5'>
                                    <div className='h-[194px] md:h-[375px] rounded-2xl lg:rounded-[32px]' style={{
                                        background: `linear-gradient(180deg, rgba(41, 27, 13, 0.27) 0%, rgba(41, 27, 13, 0.90) 100%), url(${img2})`,
                                        backgroundPosition: 'center',
                                        backgroundSize: '206.206% 100%',
                                        backgroundRepeat: 'no-repeat'
                                    }}>
                                        <a href="/daily-hayom-yom" onClick={(event) => showDailyHayomYom(event)} className='cursor-pointer text-xl xs:text-2xl lg:text-[32px] text-whiteGrey font-extrabold leading-[normal] h-full flex items-end p-3 lg:p-6'>
                                            Daily Hayom Yom
                                        </a>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='md:w-[42%]  space-y-4 md:space-y-6'>
                            <div className="flex  gap-x-4 md:gap-x-6">
                                <div className='w-2/4 md:w-3/5'>
                                    <div className='h-[138px] md:h-[179px] rounded-2xl lg:rounded-[32px]' style={{
                                        background: `linear-gradient(180deg, rgba(41, 27, 13, 0.27) 0%, rgba(41, 27, 13, 0.90) 100%), url(${img3})`,
                                        backgroundPosition: 'center',
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat'
                                    }}>
                                        <a href="/weekly-parsha-classes" onClick={(event) => showWeeklyParsh(event)} className='cursor-pointer text-xl xs:text-2xl lg:text-[32px] text-whiteGrey font-extrabold leading-[normal] h-full flex items-end p-3 lg:p-6'>
                                            Parsha of the week
                                        </a>
                                    </div>
                                </div>
                                <div className='w-2/4 md:w-2/5'>
                                    <div className='h-[138px] md:h-[179px] rounded-2xl lg:rounded-[32px]' style={{
                                        background: `linear-gradient(180deg, rgba(41, 27, 13, 0.27) 0%, rgba(41, 27, 13, 0.90) 100%), url(${img4})`,
                                        backgroundPosition: 'center',
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat'
                                    }}>
                                        <a href="/monthly-classes" onClick={(event) => showMonthlyClasses(event)} className='cursor-pointer text-xl xs:text-2xl lg:text-[32px] text-whiteGrey font-extrabold leading-[normal] h-full flex items-end p-3 lg:p-6'>
                                            {monthName}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='md:h-[172px] p-3 sm:p-6 md:p-3 lg:p-6 text-whiteGrey rounded-2xl lg:rounded-[32px] bg-primaryDark'>

                                    <div className='mb-4'>
                                        <div className='text-lg sm:text-xl xl:text-2xl font-extrabold leading-[normal]'>
                                            Never miss out on a new class again!
                                        </div>
                                        <span className='text-sm font-normal leading-[normal]'>
                                            Get updated with all the latest content.
                                        </span>
                                    </div>
                                    <form onSubmit={handleSubmit} className="flex flex-col xs:flex-row justify-between gap-3">
                                        <input
                                            type="email"
                                            placeholder="Enter your email"
                                            value={email}
                                            onChange={handleEmailChange}
                                            className="py-3 px-4 border rounded-3xl h-[40px] sm:h-[48px] bg-transparent outline-none w-full text-base font-normal"
                                            required
                                        />
                                        <div>
                                            <button disabled={loading} type="submit" className="bg-primaryBlue flex items-center text-whiteGrey text-base sm:text-lg md:text-base lg:text-lg font-bold leading-7 px-6 py-3 sm:px-8 sm:py-[14px] md:px-4 md:py-3 lg:px-8 lg:py-[14px] rounded-3xl h-[40px] sm:h-[48px] duration-300 disabled:opacity-50">
                                                Subscribe
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SubscribeModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                emailList={emailList}
                handleSubmit={submitSubscribe}
                loading={loading}
                selected={selected}
                setSelected={setSelected}
            />
        </>
    )
}

export default CategorySection