import { BookMarkIcon, BookMarkIconActive } from '../assets/icon-file';
import { AddbookMark, RemovebookMark } from '../service/service'
import { ColorRing } from 'react-loader-spinner';

export default function DataItemBookmark({
  classes,
  series,
  dataObj,
  prevData,
  data,
  setData,
  bookmarkLoading,
  setBookmarkLoading
}) {
  function toggleIsMarked(itemId) {
    const updatedData = data?.map(item => {
      if (item.id === itemId) {
        const data = {
          id: item.id
        }
        setBookmarkLoading({ loadingId: item.id })
        if (item.is_marked === 1) {
          RemovebookMark(data).then((result) => {
              if (result.status) {
                  setBookmarkLoading({ loadingId: null })
              }
          })
          return { ...item, is_marked: 0 };
        } else {
          AddbookMark(data).then((result) => {
              if (result.status) {
                  setBookmarkLoading({ loadingId: null })
              }
          })
          return { ...item, is_marked: 1 };
        }
      }
      return item;
    });
   
    // From some files the state data is an object exported as prevData. The data we are mapping then is a classes, series or categories property from within the data object and is exported as data.
    // From the other files the state data is an array. The data we are mapping here then is that array.
    setData(
      classes && prevData ? 
        { ...prevData, classes: updatedData } : 
      classes ? 
        updatedData : 
      series ? 
        { ...prevData, series: updatedData } : 
        { ...prevData, categories: updatedData }
    );
  }

  return (
    <>
      <div className='flex justify-end items-center pr-3 sm:pr-6'>
        {
          <div className={`w-8 h-6 sm:h-[24px] sm:w-[40px] pl-2 ${bookmarkLoading.loadingId === dataObj.id ? "sm:pl-[13px]" : "sm:pl-4"}`}>
            {
              bookmarkLoading.loadingId === dataObj.id ?
              <ColorRing
                  visible={true}
                  height={`${ window.innerWidth >= 640 ? 30 : 24 }`}
                  width={`${ window.innerWidth >= 640 ? 30 : 24 }`}
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={['#186CCE', '#186CCE', '#186CCE', '#186CCE', '#186CCE']}
              />
              :
              <span
                onClick={() => toggleIsMarked(dataObj.id)}
                className='cursor-pointer'
              >
                {
                  dataObj.is_marked === "0" ?
                  <BookMarkIcon />
                  :
                  <BookMarkIconActive />
                }
              </span>
            }
          </div>
        }
      </div> 
    </>
  )
}