
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../components/header/Header';
import Banner from '../../components/banner/Banner';
import Footer from '../../components/footer/Footer';
import { useSelector } from 'react-redux';
import { AddbookMark, RemovebookMark } from '../../service/service';
import DataItem from '../../ReuseableComponents/DataItem';

function ViewAll() {
  const userData = useSelector((state) => state.userdata.userdata)
  const navigate = useNavigate();
  const location = useLocation();
  const searchdata = location.state ? location.state.data : null;
  const [isLoggedin, setIsLoggedin] = useState(false)
  const [data, setData] = useState([])
  const [bookmarkLoading, setBookmarkLoading] = useState({ loadingId: null });

  useEffect(() => { 
    window.scrollTo(0, 0);
    setData(searchdata)
    if (userData) {

      setIsLoggedin(true)
    }
    else {
      setIsLoggedin(false)
    }
  }, [])
  // console.log('Data in view-all:', data);

  const OpenCategory = (obj) => {

    const data = obj;
    navigate(`/${data?.slug}`);

  }
  function toggleSeriesBookmark(itemId) {
    const updatedData = data?.series?.map(item => {
      if (item.id === itemId) {
        const data = {
          series_id: item.id
        }
        setBookmarkLoading({ loadingId: item.id })
        if (item.is_marked === 1) {

          RemovebookMark(data).then((result) => {
            if (result.status) {
              setBookmarkLoading({ loadingId: null })
            }
          })
          return { ...item, is_marked: 0 };
        } else {

          AddbookMark(data).then((result) => {
            if (result.status) {
              setBookmarkLoading({ loadingId: null })
            }
          })
          return { ...item, is_marked: 1 };
        }
      }
      return item;
    });

    setData({ ...data, series: updatedData });
  }

  return (
    <>
      <Header />
      <Banner title={`Showing results for: “${data?.query}” `} />
      <section className='px-2 xs:px-5 pt-6 pb-[38px] md:pt-8 md:pb-[100px]'>
        <div className='2xl:max-w-2xl xl:max-w-xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto text-primaryDark'>
          {
            (data?.type === 'class' || data?.type === 'all') &&
            <>
              <div className='text-[22px] leading-[normal] md:text-2xl font-extrabold'>
                Classes
              </div>
              <div className='py-4 sm:py-8 space-y-3 sm:space-y-4'>
                {
                  data?.classes?.map((obj) => (
                    <DataItem
                      viewAll={true}
                      classes={true}  
                      key={obj.id}
                      dataObj={obj}
                      data={data?.classes}
                      prevData={data}
                      setData={setData} 
                      bookmarkLoading={bookmarkLoading} 
                      setBookmarkLoading={setBookmarkLoading} 
                      isLoggedin={isLoggedin} 
                    />
                  ))
                }
              </div>
            </>
          }
          {
            (data?.type === 'series' || data?.type === 'all') && data?.series.length > 0 &&

            <>
              <div className='text-[22px] leading-[normal] md:text-2xl font-extrabold'>
                Series
              </div>
              <div className='py-4 sm:py-8 space-y-3 sm:space-y-4'>
                {
                  data?.series?.map((obj) => (
                    <DataItem
                      viewAll={true}
                      series={true}
                      key={obj.id}
                      dataObj={obj}
                      data={data?.series}
                      prevData={data}
                      setData={setData}
                      bookmarkLoading={bookmarkLoading}
                      setBookmarkLoading={setBookmarkLoading}
                      isLoggedin={isLoggedin}
                    />
                  ))
                }
              </div>
            </>
          }
          {
            (data?.type === 'category' || data?.type === 'all') && data?.categories.length > 0 &&
              <>
              <div className='text-[22px] leading-[normal] md:text-2xl font-extrabold'>
                Categories
              </div>
              <div className='py-4 sm:py-8 space-y-3 sm:space-y-4'>
                {
                  data.categories?.map((obj) => (
                    <DataItem
                      viewAll={true}
                      category={true} 
                      key={obj.id}
                      dataObj={obj}
                    />
                  ))
                }
              
              </div>
            </>
          }
        </div>
      </section>
      <Footer />
    </>
  )
}

export default ViewAll